import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'app/store';
import { RootState } from 'app/store/reducer';
import { ISession } from 'api';

type SessionState = {
  accessToken: string | undefined;
  accessTokenExpires: string | undefined;
  isAuthenticated: boolean;
  isRefreshing: boolean;
  refreshToken: string | undefined;
  refreshTokenExpires: string | undefined;
  userId: string | undefined;
};

const initialState: SessionState = {
  accessToken: undefined,
  accessTokenExpires: undefined,
  isAuthenticated: false,
  isRefreshing: false,
  refreshToken: undefined,
  refreshTokenExpires: undefined,
  userId: undefined
};

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    authenticate: (state: SessionState, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsRefreshing: (state: SessionState, action: PayloadAction<boolean>) => {
      state.isRefreshing = action.payload;
    },
    setSession: (state: SessionState, action: PayloadAction<ISession>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.accessTokenExpires = action.payload.accessTokenExpires;
      state.refreshToken = action.payload.refreshToken;
      state.refreshTokenExpires = action.payload.refreshTokenExpires;
      state.userId = action.payload.userId;
    },
    clearSession: (state: SessionState) => {
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.accessTokenExpires = undefined;
      state.refreshToken = undefined;
      state.refreshTokenExpires = undefined;
    }
  }
});

export const { reducer } = session;
export const { authenticate, clearSession, setIsRefreshing, setSession } = session.actions;

export function getIsAuthenticated(state: RootState): boolean {
  return state.session.isAuthenticated;
}

export function getUserId(state: RootState): string | undefined {
  return state.session.userId;
}

export function getSession(state: RootState): SessionState {
  return state.session;
}

export function login(username: string, password: string): AppThunk<void> {
  return async function loginThunk(_dispatch, _getState, api) {
    try {
      const response = await api.auth.login(username, password);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function forgotPassword(username: string): AppThunk<void> {
  return async function forgotPasswordThunk(_dispatch, _getState, api) {
    try {
      const response = await api.auth.forgotPassword(username);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function resetPassword(username: string, newPassword: string, token: string): AppThunk<void> {
  return async function resetPasswordThunk(_dispatch, _getState, api) {
    try {
      const response = await api.auth.resetPassword(username, token, newPassword);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function refreshSession(): AppThunk<Promise<ISession>> {
  return async (dispatch, _getState, api) => {
    try {
      dispatch(setIsRefreshing(true));
      const response = await api.auth.refreshSession();
      dispatch(setSession(response));
      dispatch(setIsRefreshing(false));
      return Promise.resolve(response);
    } catch (error) {
      dispatch(clearSession());
      return Promise.reject(error);
    }
  };
}
