import { Api } from 'api';
import { ContentEndpointInterfaces } from 'types';

export enum CONTENT_ENDPOINTS {
  CONTENT = '/content',
  PRIVACY_POLICY = '/content/1',
  TERMS_OF_USE = '/content/3',
  INITIAL_DASHBOARD = '/initial-dashboard',
}

export class ContentService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/shards';
    this.api = api;
  }

  public fetch(path: CONTENT_ENDPOINTS) {
    return this.api.request<any>({
      method: 'GET',
      uri: this.baseUrl + path,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': this.api.shardKey,
      },
    });
  }

  public update<A extends CONTENT_ENDPOINTS>(path: A, values: Partial<ContentEndpointInterfaces[A]>) {
    return this.api.put<void>(this.baseUrl + path, values);
  }
}
