import React from 'react';
import qs from 'qs';
import { Switch, Route, Redirect, Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  AppLayout,
  AuthLayout,
  ForgotPasswordForm,
  LoginForm,
  ResetPasswordForm,
  SelectShard,
  Session,
  SessionTimer,
  SubRouter
} from 'components';
import { getIsAuthenticated } from 'utilities';
import { history } from 'app/store/reducer';
import { PATH, routes } from 'routes';
import { AccountSettings, EmailTemplates, Users } from 'pages';

export const App = (): JSX.Element => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  function parseParams(search: string) {
    const { email, token } = qs.parse(search, { ignoreQueryPrefix: true });

    return {
      email,
      token
    };
  }
  return (
    <Router history={history}>
      <Session>
        <Switch>
          <Route
            exact
            path={[PATH.ACTIVATE_ACCOUNT, PATH.RESET_PASSWORD, PATH.FORGOT_PASSWORD, PATH.LOGIN, PATH.SHARDS]}
          >
            <AuthLayout>
              <Route
                path={PATH.SHARDS}
                render={() => (
                  <RequiredAuth isAuthenticated={isAuthenticated} redirectTo="/login">
                    <SelectShard />
                  </RequiredAuth>
                )}
              />

              <Route
                path={PATH.RESET_PASSWORD}
                render={props => (
                  <ResetPasswordForm
                    buttonText="Change password"
                    email={parseParams(props.location.search).email}
                    token={parseParams(props.location.search).token}
                  />
                )}
              />

              <Route
                path={PATH.ACTIVATE_ACCOUNT}
                render={props => (
                  <ResetPasswordForm
                    buttonText="Activate account"
                    email={parseParams(props.location.search).email}
                    token={parseParams(props.location.search).token}
                  />
                )}
              />

              <Route path={PATH.FORGOT_PASSWORD}>
                <ForgotPasswordForm />
              </Route>

              <Route
                path={PATH.LOGIN}
                render={() => (!isAuthenticated ? <LoginForm /> : <Redirect to={{ pathname: '/' }} />)}
              />
            </AuthLayout>
          </Route>

          <Route
            path={[
              PATH.ACCOUNT_SETTINGS,
              PATH.AUTO_ALLOCATIONS,
              PATH.CONTACT_INFORMATION,
              PATH.EMAIL_TEMPLATES,
              PATH.KEYS,
              PATH.NAVIGATION,
              PATH.RESIDENTIAL_SIGN_UP,
              PATH.ROOT,
              PATH.SETTINGS,
              PATH.THEME
            ]}
            render={() => (
              <RequiredAuth isAuthenticated={isAuthenticated} redirectTo="/login">
                <AppLayout>
                  <SessionTimer />

                  <Route exact path={PATH.ACCOUNT_SETTINGS}>
                    <AccountSettings />
                  </Route>

                  <Route exact path={PATH.USERS}>
                    <Users />
                  </Route>

                  <Route exact path={PATH.EMAIL_TEMPLATES} children={<EmailTemplates />} />

                  <Route
                    path={PATH.RESIDENTIAL_SIGN_UP}
                    render={props => <SubRouter baseUrl={props.match.path} routes={routes.residentialSignup} baseUrlPath="/homepage" />}
                  />

                  <Route
                    path={PATH.CONTENT}
                    render={props => <SubRouter baseUrl={props.match.path} routes={routes.content} baseUrlPath="/homepage" />}
                  />

                  <Route
                    path={PATH.CONFIGURATION}
                    render={props => <SubRouter baseUrl={props.match.path} routes={routes.configuration} baseUrlPath="/homepage" />}
                  />

                  <Route
                    path={PATH.ENTITIES}
                    render={props => <SubRouter baseUrl={props.match.path} routes={routes.entities} baseUrlPath="/homepage" />}
                  />

                  <Route path={PATH.ROOT} exact render={() => <Redirect to="configuration/contact-information" />} />
                </AppLayout>
              </RequiredAuth>
            )}
          ></Route>
        </Switch>
      </Session>
    </Router>
  );
};

const RequiredAuth = ({ children, isAuthenticated, redirectTo }: any) => {
  return isAuthenticated ? children : <Redirect to={redirectTo} />;
};
