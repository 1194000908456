import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';

import { createBrowserHistory } from 'history';
import { reducer as session, shardReducer as shard } from 'utilities';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

export const reducer = combineReducers({
  router: routerReducer,
  session,
  shard
});

export const middleware = routerMiddleware;

export const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware)
});
export const history = createReduxHistory(store);
export type RootState = ReturnType<typeof reducer>;
