import { Api } from 'api';
import { IAddUserValues, ProfileFormValues, ChangePasswordFormValues } from 'types';

export class AccountService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/account';
    this.api = api;
  }

  public fetchAccount(userId: string | undefined) {
    if (userId) {
      return this.api.get<any>(`${this.baseUrl}/${userId}`);
    }

    return Promise.reject({
      error: true
    });
  }

  public updateAccount(id: string, values: ProfileFormValues) {
    return this.api.put(this.baseUrl, {
      id,
      ...values
    });
  }

  public changePassword(values: ChangePasswordFormValues) {
    return this.api.post(`${this.baseUrl}/change-password`, {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword
    });
  }

  public fetchUsers() {
    return this.api.get<any>(`${this.baseUrl}/admin-users`);
  }

  public addUser(values: IAddUserValues) {
    return this.api.post(`${this.baseUrl}/register`, {
      ...values,
      role: 'Admin'
    });
  }
}
