import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

import { ContentWrapper } from 'components';
import { useAccount } from 'hooks';

import { ChangePasswordForm, ProfileForm } from './components';

export const AccountSettings = (): JSX.Element => {
  const { data, error, loading } = useAccount();

  const profileValues = {
    firstName: (data as any).firstName,
    lastName: (data as any).lastName
  };

  return (
    <ContentWrapper error={error} loading={loading}>
      {!!data && (
        <>
          <Card className="mb-3">
            <CardHeader tag="h5">Profile</CardHeader>

            <CardBody>
              <ProfileForm initialValues={profileValues} />
            </CardBody>
          </Card>

          <Card className="mb-3">
            <CardHeader tag="h5">Change Password</CardHeader>

            <CardBody>
              <ChangePasswordForm />
            </CardBody>
          </Card>
        </>
      )}
    </ContentWrapper>
  );
};
