import { faAddressCard, faPaintRoller, faExchange, faKey, faSlidersH } from '@fortawesome/pro-duotone-svg-icons';

import { AutoAllocations, Contact, Keys, Settings, Theme, SandboxSettings } from 'pages';
import { Route } from 'types';

export const baseRoute = '/configuration';
export const contactRoute = '/contact-information';
export const navigationRoute = '/navigation';
export const themeRoute = '/theme';
export const autoAllocationsRoute = '/auto-allocations';
export const keysRoute = '/keys';
export const settingsRoute = '/settings';
export const sandboxSettingsRoute = '/sandbox-settings';

export const routes: Route[] = [
  {
    component: Contact,
    key: 'contact',
    name: 'Contact Information',
    path: contactRoute,
    icon: faAddressCard
  },
  {
    component: Theme,
    key: 'theme',
    name: 'Theme',
    path: themeRoute,
    icon: faPaintRoller
  },
  {
    component: AutoAllocations,
    key: 'Auto Allocations',
    name: 'Auto Allocations',
    path: autoAllocationsRoute,
    icon: faExchange
  },
  {
    component: Keys,
    key: 'Keys',
    name: 'Keys',
    path: keysRoute,
    icon: faKey
  },
  {
    component: Settings,
    key: 'Settings',
    name: 'Settings',
    path: settingsRoute,
    icon: faSlidersH
  },
  {
    component: SandboxSettings,
    path: sandboxSettingsRoute,
    key: 'Sandbox Settings',
    name: 'Sandbox Settings',
    hide: true
  }
];
