import React from 'react';
import get from 'lodash/get';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'app/store';
import { ISession } from 'api';
import { Button } from 'components';
import { useForm } from 'hooks';
import { authenticate, login, setSession } from 'utilities';

export type LoginFormValues = {
  email: string;
  password: string;
};

export const LoginForm = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const message = get(location, 'state.message');

  function onSubmit(): any {
    return dispatch(login(values.email, values.password));
  }

  function handleSuccess(session: ISession) {
    dispatch(setSession(session));
    dispatch(authenticate(true));
    dispatch(push('/'));
  }

  function handleFailure() {
    setValues({
      ...values,
      password: ''
    });
  }

  const initialValues: LoginFormValues = {
    email: '',
    password: ''
  };

  const form = useForm<LoginFormValues>(initialValues, onSubmit, handleSuccess, handleFailure);
  const { error, isSubmitting, values, handleInputChange, handleSubmit, setValues } = form;

  return (
    <Form>
      {Boolean(error) && (
        <Alert className="text-center" color="danger">
          Incorrect email address or password
        </Alert>
      )}

      {Boolean(message) && <Alert color="info">{message}</Alert>}

      <FormGroup>
        <Label for="email" className="font-weight-bold">
          Email Address
        </Label>

        <Input
          autoComplete="username"
          autoFocus
          disabled={isSubmitting}
          id="email"
          name="email"
          onChange={handleInputChange}
          tabIndex={1}
          type="email"
          value={values.email}
        />
      </FormGroup>

      <FormGroup>
        <Label for="password" className="d-flex font-weight-bold">
          Password
          <Link to="/forgot-password" className="ml-auto">
            Forgot password?
          </Link>
        </Label>

        <Input
          autoComplete="current-password"
          disabled={isSubmitting}
          id="password"
          name="password"
          onChange={handleInputChange}
          tabIndex={2}
          type="password"
          value={values.password}
        />
      </FormGroup>

      <Button
        block
        loading={isSubmitting}
        color="primary"
        className="font-weight-bold"
        type="submit"
        onClick={handleSubmit}
        tabIndex={3}
      >
        Sign in
      </Button>
    </Form>
  );
};
