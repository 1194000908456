import { Api } from 'api';

export interface ISession {
  accessToken: string | undefined;
  accessTokenExpires: string | undefined;
  refreshToken: string | undefined;
  refreshTokenExpires: string | undefined;
  userId: string | undefined;
}

export class Session implements ISession {
  private api: Api;

  public accessToken: string | undefined = undefined;
  public accessTokenExpires: string | undefined = undefined;
  public refreshToken: string | undefined = undefined;
  public refreshTokenExpires: string | undefined = undefined;
  public userId: string | undefined = undefined;

  constructor(api: Api, session: ISession | undefined) {
    this.api = api;

    if (session) {
      this.create(session);
    }
  }

  create(session: ISession) {
    this.accessToken = session.accessToken;
    this.accessTokenExpires = session.accessTokenExpires;
    this.refreshToken = session.refreshToken;
    this.refreshTokenExpires = session.refreshTokenExpires;
    this.userId = session.userId;
  }
}
