import React from 'react';

import logo from 'assets/logo.png';
import { EnvironmentBanner } from 'components/EnvironmentBanner';

import styles from './AuthLayout.module.scss';

export const AuthLayout = (props: any): JSX.Element => {
  const { children } = props;

  return (
    <div className={styles.app}>
      <div className={styles['app-container']}>
        <div className={styles.logo}>
          <img alt="SunCentral Logo" src={logo} className="img-fluid" />
        </div>
        <EnvironmentBanner />
        <div className={styles['form-container']}>{children}</div>
      </div>
    </div>
  );
};
