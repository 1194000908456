import axios, { AxiosInstance } from 'axios';

import { 
  AccountService,
  AssociatedUtilityService,
  AuthService,
  ConfigurationService,
  ContactService,
  ContentService,
  ResidentialService,
  ShardService 
} from './services';
   
import { ISession, Session } from './models';
import { OptionsWithUri } from 'request-promise';
import { RequestClient } from './RequestClient';

export class Api {
  private client: RequestClient;
  private axios: AxiosInstance;

  public auth: AuthService;
  public account: AccountService;
  public associatedUtility: AssociatedUtilityService;
  public contact: ContactService;
  public request: <T>(args: OptionsWithUri) => Promise<T>;
  public residential: ResidentialService;
  public configuration: ConfigurationService;
  public content: ContentService;
  public session: Session;
  public shard: ShardService;
  public shardKey: string | undefined = undefined;

  constructor(session?: ISession, shardKey?: string) {
    this.auth = new AuthService(this);
    this.account = new AccountService(this);
    this.associatedUtility = new AssociatedUtilityService(this);
    this.client = new RequestClient();
    this.contact = new ContactService(this);
    this.content = new ContentService(this);
    this.request = this.requestGenerator();
    this.residential = new ResidentialService(this);
    this.configuration = new ConfigurationService(this);
    this.session = new Session(this, session);
    this.shard = new ShardService(this);
    this.shardKey = shardKey;

    // hacked this in to get image uploads working...
    // bug with request-promise self._form
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    });
  }

  public get<T>(uri: string) {
    return this.request<T>({
      method: 'GET',
      uri,
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`,
        'X-Shard-Key': this.shardKey
      }
    });
  }

  public head<T>(uri: string) {
    this.request<T>({
      method: 'HEAD',
      uri,
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`,
        'X-Shard-Key': this.shardKey
      }
    });
  }

  public delete<T>(uri: string) {
    return this.request<T>({
      method: 'DELETE',
      uri,
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`,
        'X-Shard-Key': this.shardKey
      }
    });
  }

  public put<T>(uri: string, body: any) {
    return this.request<T>({
      method: 'PUT',
      uri,
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`,
        'X-Shard-Key': this.shardKey
      },
      body: JSON.stringify(body)
    });
  }

  public patch<T>(uri: string, body: any) {
    return this.request<T>({
      method: 'PATCH',
      uri,
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`,
        'X-Shard-Key': this.shardKey
      },
      body: JSON.stringify(body)
    });
  }

  public post<T>(uri: string, body: any) {
    return this.request<T>({
      method: 'POST',
      uri,
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`,
        'X-Shard-Key': this.shardKey
      },
      body: JSON.stringify(body)
    });
  }

  public postFile<T>(uri: string, formData: any) {
    return this.axios.post(uri, formData, {
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`,
        'X-Shard-Key': this.shardKey,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      }
    });
  }

  private requestGenerator<T>() {
    return (args: OptionsWithUri) => {
      return new Promise<T>((resolve, reject) => {
        this.client.push({
          ...args,
          callback: (error, results) => {
            if (error) {
              return reject(error);
            }

            return resolve(results);
          }
        });
      });
    };
  }
}
