import React from 'react';
import { Nav, NavLink, NavItem, Card, CardHeader, CardBody } from 'reactstrap';
import { NavLink as Link, Route } from 'react-router-dom';

import { PageKey, RouterProps } from 'types';

export const ResidentialHomePage = (props: RouterProps): JSX.Element => {
  const { baseUrl, routes } = props;

  return (
    <Card>
      <CardHeader>
        <Nav pills card>
          {routes.map(({ name, path }) => (
            <NavItem key={path}>
              <NavLink exact tag={Link} to={`${baseUrl}${path}`}>
                {name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </CardHeader>

      <CardBody>
        {routes.map(route => {
          const { component: Component, key, name, path, routes } = route;

          return (
            <Route
              exact
              key={key}
              path={`${baseUrl}${path}`}
              render={props => (
                <Component baseUrl={props.match.path} pageKey={key as PageKey} name={name} routes={routes} />
              )}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};
