import { 
  HomePageBlocks,
  HomePageEditor,
  HomePageFormEditor,
  ResidentialFaqs,
  ResidentialHomePage,
  ResidentialPage 
} from 'pages';
import { Route } from 'types';

export const baseRoute = '/residential-sign-up';
export const addUtilityRoute = `${baseRoute}/add-utility`;
export const confirmUtilityRoute = `${baseRoute}/confirm-utility`;
export const connectUtilityRoute = `${baseRoute}/connect-utility`;
export const creditCheckRoute = `${baseRoute}/credit-check`;
export const faqsRoute = `${baseRoute}/faqs`;
export const homepageRoute = `${baseRoute}/homepage`;
export const homepageBlocksRoute = `${baseRoute}/homepage/blocks`;
export const homepageFormRoute = `${baseRoute}/homepage/form`;
export const inquiryFailureRoute = `${baseRoute}/inquiry-failure`;
export const inquiryRoute = `${baseRoute}/inquiry`;
export const inquirySuccessRoute = `${baseRoute}/inquiry-success`;
export const plansRoute = `${baseRoute}/plans`;
export const reviewRoute = `${baseRoute}/review`;
export const addPaymentRoute = `${baseRoute}/add-payment`;
export const finalizeRoute = `${baseRoute}/finalize`;
export const setPasswordRoute = `${baseRoute}/set-password`;
export const verifyAccountRoute = `${baseRoute}/verify-account`;

export const routes: Route[] = [
  {
    component: ResidentialHomePage,
    key: 'homepage',
    name: 'Homepage',
    path: '/homepage',
    routes: [
      {
        component: HomePageEditor,
        exact: true,
        key: 'content',
        name: 'Content',
        path: '/'
      },

      {
        component: HomePageBlocks,
        exact: true,
        key: 'blocks',
        name: 'Blocks',
        path: '/blocks'
      },

      {
        component: HomePageFormEditor,
        exact: true,
        key: 'form',
        name: 'Form',
        path: '/form'
      }
    ]
  },

  {
    component: ResidentialPage,
    key: 'inquiry',
    name: 'Initial Inquiry',
    path: '/inquiry'
  },

  {
    component: ResidentialPage,
    key: 'inquiryFailure',
    name: 'Initial Inquiry (Failure)',
    path: '/inquiry-failure'
  },

  {
    component: ResidentialPage,
    key: 'inquirySuccess',
    name: 'Initial Inquiry (Success)',
    path: '/inquiry-success'
  },

  {
    component: ResidentialPage,
    key: 'verifyAccount',
    name: 'Verify Account',
    path: '/verify-account'
  },

  {
    component: ResidentialPage,
    key: 'setPassword',
    name: 'Set Password',
    path: '/set-password'
  },

  {
    component: ResidentialPage,
    key: 'connectUtility',
    name: 'Connect Utility',
    path: '/connect-utility'
  },

  {
    component: ResidentialPage,
    key: 'confirmUtility',
    name: 'Confirm Utility',
    path: '/confirm-utility'
  },

  {
    component: ResidentialPage,
    key: 'addUtility',
    name: 'Add Utility',
    path: '/add-utility'
  },

  {
    component: ResidentialPage,
    key: 'plans',
    name: 'Plans',
    path: '/plans'
  },

  {
    component: ResidentialPage,
    key: 'review',
    name: 'Review',
    path: '/review'
  },

  {
    component: ResidentialPage,
    key: 'addPayment',
    name: 'Add Payment',
    path: '/add-payment'
  },

  {
    component: ResidentialPage,
    key: 'finalize',
    name: 'Finalize',
    path: '/finalize'
  },

  {
    component: ResidentialPage,
    key: 'creditCheck',
    name: 'Credit Check',
    path: '/credit-check'
  },

  {
    component: ResidentialFaqs,
    key: 'faqs',
    name: 'FAQs',
    path: '/faqs'
  }
];
