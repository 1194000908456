import { AppThunk } from 'app/store';
import { CONFIGURATION_ENDPOINTS } from 'api';
import { IConfigContent, IInvoicePreview, ImageResponse } from 'types';
import { getErrorMessage } from 'utilities';

export function update<A extends CONFIGURATION_ENDPOINTS>(path: A, values: IConfigContent[A]): AppThunk<Promise<void>> {
  return async function updatePageThunk(_dispatch, _getState, api) {
    try {
      await api.configuration.update(path, values);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function post<A extends CONFIGURATION_ENDPOINTS>(path: A, values?: any, fullPath?: string): AppThunk<Promise<void>> {
  return async function postPageThunk(_dispatch, _getState, api) {
    try {
      await api.configuration.post(path, values, fullPath);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function createInvoicePreview<A extends CONFIGURATION_ENDPOINTS>(
  path: A,
  values?: any,
  fullPath?: string
): AppThunk<Promise<IInvoicePreview>> {
  return async (_dispatch, _getState, api) => {
    try {
      const data = await api.configuration.post(path, values, fullPath);
      return Promise.resolve((data as unknown) as IInvoicePreview);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function checkInvoicePreview(ticket: string): AppThunk<Promise<boolean>> {
  return async (_dispatch, _getState, api) => {
    try {
      let response = await api.configuration.checkInvoicePreview(ticket);

      return Promise.resolve(response.statusCode !== 404);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function getInvoicePreview(ticket: string): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, api) => {
    try {
      let response = await api.configuration.getInvoicePreview(ticket);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function postFile<A extends CONFIGURATION_ENDPOINTS>(path: A, formData: any): AppThunk<Promise<ImageResponse>> {
  return async function postPageThunk(_dispatch, _getState, api) {
    try {
      const data = await api.configuration.postFile(path, formData);
      return Promise.resolve((data as unknown) as ImageResponse);
    } catch (error) {
      // TODO: use optional chaining when this lands
      // https://github.com/facebook/create-react-app/compare/v3.4.0...2030ee162df0ca8f19efedcf0447e02613e2f53d
      if (getErrorMessage(error)) {
        return Promise.reject(getErrorMessage(error));
      }
      return Promise.reject(error);
    }
  };
}
