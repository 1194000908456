import React, { ReactElement } from 'react';
import { Alert } from 'reactstrap';

type ContentWrapperProps = {
  children?: ReactElement | boolean;
  error: any | undefined;
  loading: boolean;
};

export const ContentWrapper = (props: ContentWrapperProps) => {
  const { children, error, loading } = props;

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <Alert color="danger">An error has occurred. Please try again later.</Alert>;
  }

  return children as ReactElement;
};