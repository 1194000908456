import React from 'react';
import { useFormik } from 'formik';
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { useChangePassword } from 'hooks';
import { ChangePasswordFormValues } from 'types';

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .required('Password is required')
    .min(10, 'Passwords must be at least 10 characters.'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords do not match.')
    .required('Password confirmation is required')
});

export const ChangePasswordForm = (): JSX.Element => {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  };

  const { onSubmit: _onSubmit } = useChangePassword();

  function onSubmit() {
    _onSubmit(values).then(() => {
      resetForm();
    });
  }

  const formik = useFormik<ChangePasswordFormValues>({
    initialValues,
    onSubmit,
    validationSchema: ChangePasswordSchema
  });

  const { handleBlur, touched, errors, handleChange, handleSubmit, isSubmitting, values, resetForm } = formik;

  return (
    <Form>
      <FormGroup row>
        <Label className="text-right" for="currentPassword" sm={3}>
          Current Password <span className="text-danger">*</span>
        </Label>

        <Col>
          <Input
            id="currentPassword"
            name="currentPassword"
            type="password"
            onBlur={handleBlur}
            disabled={isSubmitting}
            onChange={handleChange}
            value={values.currentPassword}
          />

          {Boolean(errors.currentPassword && touched.currentPassword) && (
            <small className="form-text text-danger">{errors.currentPassword}</small>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label className="text-right" for="newPassword" sm={3}>
          New Password <span className="text-danger">*</span>
        </Label>

        <Col>
          <Input
            id="newPassword"
            name="newPassword"
            onBlur={handleBlur}
            type="password"
            disabled={isSubmitting}
            onChange={handleChange}
            value={values.newPassword}
          />

          {Boolean(errors.newPassword && touched.newPassword) && (
            <small className="form-text text-danger">{errors.newPassword}</small>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label className="text-right" for="newPasswordConfirmation" sm={3}>
          Confirm New Password <span className="text-danger">*</span>
        </Label>

        <Col>
          <Input
            id="newPasswordConfirmation"
            name="newPasswordConfirmation"
            onBlur={handleBlur}
            type="password"
            disabled={isSubmitting}
            onChange={handleChange}
            value={values.newPasswordConfirmation}
          />

          {Boolean(errors.newPasswordConfirmation && touched.newPasswordConfirmation) && (
            <small className="form-text text-danger">{errors.newPasswordConfirmation}</small>
          )}
        </Col>
      </FormGroup>

      <FormGroup row className="mb-0">
        <Col sm={{ size: 9, offset: 3 }}>
          <Button disabled={isSubmitting} color="primary" type="submit" onClick={() => handleSubmit()}>
            Update Password
          </Button>

          <Button tag={Link} color="link" to="/forgot-password">
            I forgot my password
          </Button>
        </Col>
      </FormGroup>
    </Form>
  );
};
