import qs from 'qs';
import { Api, ISession } from 'api';

interface TokenResponse {
  access_token: string;
  type: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
  userName: string;
  userId: string;
  role: string;
  issued: string;
  expires: string;
  refresh_token_issued: string;
  refresh_token_expires: string;
  hostname: string;
}

export class AuthService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/token';
    this.api = api;
  }

  public login(username: string, password: string) {
    return new Promise<ISession>((resolve, reject) => {
      this.api
        .request<TokenResponse>({
          method: 'POST',
          uri: this.baseUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: qs.stringify({
            client_id: process.env.REACT_APP_CLIENT_ID,
            grant_type: 'password',
            username,
            password
          })
        })
        .then(
          response => {
            const session = {
              accessToken: response.access_token,
              accessTokenExpires: response.expires,
              refreshToken: response.refresh_token,
              refreshTokenExpires: response.refresh_token_expires,
              userId: response.userId
            };

            this.api.session.create(session);

            return resolve(session);
          },
          error => reject(error)
        )
        .catch(error => reject(error));
    });
  }

  public forgotPassword(username: string) {
    return this.api.request<any>({
      method: 'POST',
      uri: '/account/forgot-password',
      qs: {
        username
      }
    });
  }

  public resetPassword(username: string, token: string, newPassword: string) {
    return this.api.request<any>({
      method: 'POST',
      uri: '/account/reset-password',
      body: JSON.stringify({
        username,
        token,
        newPassword,
        shouldReset: ''
      })
    });
  }

  public refreshSession() {
    const { session } = this.api;
    const { refreshToken } = session;

    return new Promise<ISession>((resolve, reject) => {
      this.api
        .request<TokenResponse>({
          method: 'POST',
          uri: this.baseUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: qs.stringify({
            client_id: process.env.REACT_APP_CLIENT_ID,
            grant_type: 'refresh_token',
            refresh_token: refreshToken
          })
        })
        .then(
          response => {
            const session = {
              accessToken: response.access_token,
              accessTokenExpires: response.expires,
              refreshToken: response.refresh_token,
              refreshTokenExpires: response.refresh_token_expires,
              userId: response.userId
            };

            this.api.session.create(session);

            return resolve(session);
          },
          error => reject(error)
        )
        .catch(error => reject(error));
    });
  }
}
