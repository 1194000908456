import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from 'app/store';

import { getUserId } from 'utilities';

export function useAccount() {
  const [data, setData] = useState({});
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const userId = useSelector(getUserId);

  useEffect(() => {
    setError(undefined);
    setData({});
    setLoading(true);

    api.account
      .fetchAccount(userId)
      .then(setData, setError)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [userId]);

  return {
    data,
    error,
    loading
  };
}