import { Api } from 'api';
import { IConfigContent } from 'types';

export enum CONFIGURATION_ENDPOINTS {
  SECRETS = '/secrets',
  CONTACT = '/contact-info-nav',
  SETTINGS = '/settings',
  SANDBOX_SETTINGS = '/sandbox-settings',
  AUTO_ALLOCATION = '/auto-allocation',
  EMAIL_TEMPLATES = '/email',
  THEME = '/themes-display',
  INVOICE_INVOICE_PREVIEW = '/themes-display/invoices/preview',
  POST_IMAGE = '/themes-display/image-upload'
}

export enum IMAGE_PURPOSE {
  MainLogo = '0',
  BackgroundImage = '1',
  Favicon = '2',
  InvoiceLogo = '3',
  ResidentialBackgroundImage = '4',
  ResidentialPartnerLogo = '5'
}

/**
 * A wrapper around all of the main "Configure Shard" pages.
 */
export class ConfigurationService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/shards';
    this.api = api;
  }

  /**
   * @param path one of the CONFIGURATION_ENDPOINTS enum
   */
  public fetch(path: CONFIGURATION_ENDPOINTS) {
    return this.api.request<any>({
      method: 'GET',
      uri: this.baseUrl + path,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': this.api.shardKey
      }
    });
  }

  /**
   * @param path one of the CONFIGURATION_ENDPOINTS enum
   * @param values an object that matches our IConfigContent shape
   */
  public update<A extends CONFIGURATION_ENDPOINTS>(path: A, values: Partial<IConfigContent[A]>) {
    return this.api.patch<void>(this.baseUrl + path, values);
  }

  /**
   * @param path one of the CONFIGURATION_ENDPOINTS enum
   * @param values an object that matches our IConfigContent shape
   */
  public post<A extends CONFIGURATION_ENDPOINTS>(path: A, values?: Partial<IConfigContent[A]>, fullPath?: string) {
    const uri = fullPath ? fullPath : path;
    return this.api.post<void>(this.baseUrl + uri, values);
  }

  /**
   * @param path one of the CONFIGURATION_ENDPOINTS enum
   * @param formData an object that matches our IConfigContent shape
   */
  public postFile<A extends CONFIGURATION_ENDPOINTS>(path: A, formData: any) {
    return this.api.postFile<void>(this.baseUrl + path, formData);
  }

  public checkInvoicePreview(ticket: string) {
    return this.api.request<any>({
      method: 'HEAD',
      uri: this.baseUrl + `${CONFIGURATION_ENDPOINTS.INVOICE_INVOICE_PREVIEW}/${ticket}`,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': this.api.shardKey
      }
    });
  }

  public getInvoicePreview(ticket: string) {
    return this.api.request<any>({
      method: 'GET',
      uri: this.baseUrl + `${CONFIGURATION_ENDPOINTS.INVOICE_INVOICE_PREVIEW}/${ticket}`,
      encoding: 'binary',
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': this.api.shardKey,
        'Content-Type': 'application/pdf'
      }
    });
  }
}
