import { Api } from 'api';
import { 
  IResidentialHomepage,
  PageKey,
  IFaqItem, 
  IPageBlock 
} from 'types';

export class ResidentialService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/shards/residential';
    this.api = api;
  }

  public fetch() {
    return this.api.request<any>({
      method: 'GET',
      uri: this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': this.api.shardKey
      }
    });
  }

  public updatePage(pageKey: PageKey, values: Partial<IPageBlock>) {
    return this.api.patch<void>(this.baseUrl, {
      content: {
        [pageKey]: values
      }
    });
  }

  public updateHomePage(values: Partial<IResidentialHomepage>) {
    return this.api.patch<void>(this.baseUrl, {
      content: {
        homepage: values
      }
    });
  }

  public updateHomePageForm(values: Partial<IPageBlock>) {
    return this.api.patch<void>(this.baseUrl, {
      content: {
        homepage: {
          form: values
        }
      }
    });
  }

  public updateHomePageBlocks(blocks: IPageBlock[]) {
    return this.api.patch<void>(this.baseUrl, {
      content: {
        homepage: {
          blocks: blocks.map(({ body, title }) => ({
            body,
            title
          }))
        }
      }
    });
  }

  public updateFaqs(faqs: IFaqItem[]) {
    return this.api.patch<void>(this.baseUrl, {
      faqs: {
        items: faqs.map(({ content, title }) => ({
          content,
          title
        }))
      }
    });
  }
}
