import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import { getActiveShardKey } from 'utilities';

import * as Styles from './AppLayout.styles';
import { Navigation } from './components';
import { Header } from 'components/Header';

const ToastContainer = (props: any) => <DefaultToastContainer style={{ zIndex: 9999 }} {...props} />;

export const AppLayout = (props: any): JSX.Element => {
  const { children } = props;
  const activeShardKey = useSelector(getActiveShardKey);

  if (activeShardKey) {
    return (
      <>
        <div style={{ position: 'relative', zIndex: 100 }}>
          <Header />
        </div>
        <Styles.App>
          <Navigation />
          <Styles.Content>
            <ToastProvider autoDismiss components={{ ToastContainer }}>
              <Container fluid>
                <Row>
                  <Col>{children}</Col>
                </Row>
              </Container>
            </ToastProvider>
          </Styles.Content>
        </Styles.App>
      </>
    );
  }

  return <Redirect to="/shards" />;
};
