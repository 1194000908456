import React, { useEffect, useState } from 'react';
import { HeaderWrapper } from './Header.styles';
import { ShardList } from 'components/ShardList';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchShards,
  getShards,
  getActiveShard,
  setActiveShardKey,
  clearSession,
  clearActiveShardKey,
} from 'utilities';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { faEllipsisH, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'components/Icon';

const Menu = ({ activeShard, dispatch }: any) => {
  function handleLogout() {
    dispatch(clearSession());
    dispatch(clearActiveShardKey());
  }
  const name = activeShard?.isSeedShard
    ? 'Seed'
    : activeShard?.isSandboxShard
    ? 'Sandbox'
    : activeShard?.isDemoShard
    ? 'Demo'
    : activeShard.name;

  const protocol = 'https://';

  return (
    <div className="font-weight-normal text-left menu">
      <a target="_blank" rel="noopener noreferrer" href={`${protocol}${activeShard.hostname}`} className="mb-1">
        Open {name} Platform{' '}
        <span className="icon">
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={`${protocol}${activeShard.hostname}/signup`} className="mb-1">
        Open {name} Sign Up Flow{' '}
        <span className="icon">
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </span>
      </a>
      <p className="mb-0 signout" onClick={() => handleLogout()}>
        Sign Out
      </p>
    </div>
  );
};

export type EnvironmentBannerProps = {
  forLogin?: boolean;
};

export const Header = (props: EnvironmentBannerProps): JSX.Element | null => {
  const { forLogin } = props;
  const dispatch = useDispatch();
  const shards = useSelector(getShards);
  const activeShard = useSelector(getActiveShard);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [toggleOn, setToggleOn] = useState(activeShard?.isSandboxShard ? true : false);

  useEffect(() => {
    if (!forLogin) {
      dispatch(fetchShards());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const epClasses = `${
    activeShard?.isSeedShard ? 'seed' : activeShard?.isDemoShard ? 'demo' : activeShard?.isSandboxShard ? 'sandbox' : ''
  } text-center font-weight-bold py-1 d-flex align-items-center ${
    forLogin ? 'rounded login' : 'px-4 py-3 position-relative'
  }`;

  if (!process.env.REACT_APP_ENVIRONMENT_NAME) {
    return null;
  }

  const sandbox = shards.find((shard) => shard?.sandboxOfShardProfileId === activeShard?.id);

  const handleChange = () => {
    if (toggleOn) {
      const liveShard = shards.find((shard: { id: any }) => activeShard?.sandboxOfShardProfileId === shard.id);
      if (liveShard) {
        dispatch(setActiveShardKey(liveShard.key));
        setToggleOn(false);
      }
    } else if (sandbox) {
      dispatch(setActiveShardKey(sandbox.key));
      setToggleOn(true);
    }
  };

  return (
    <HeaderWrapper className={epClasses}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <p className="mb-0 mr-2">{process.env.REACT_APP_ENVIRONMENT_NAME} Environment</p>
          <div style={{ width: '13rem' }}>
            <ShardList shards={shards} setToggleOn={setToggleOn} className="top" />
          </div>
        </div>
        <div className="d-flex align-items-center">
          {sandbox || activeShard?.isSandboxShard ? (
            <div className="d-flex align-items-center">
              <ToggleSwitch
                toggleOn={toggleOn}
                name="sandboxTogggle"
                id="sandboxToggleSwitch"
                handleChange={handleChange}
                theme="green"
              />{' '}
              <span className="ml-2 font-weight-normal mr-4">Sandbox</span>
            </div>
          ) : null}
          <div style={{ color: '#576068', marginTop: '4.5px' }}>
            <div className="menu-container" onClick={() => setMenuIsOpen(!menuIsOpen)}>
              <Icon icon={faEllipsisH} />
            </div>
            {menuIsOpen ? <Menu activeShard={activeShard} dispatch={dispatch} /> : null}
          </div>
        </div>

        {activeShard?.isSeedShard || activeShard?.isDemoShard || activeShard?.isSandboxShard ? (
          <div
            className={`${process.env.REACT_APP_ENVIRONMENT_NAME.toLowerCase()} env-badge ${
              activeShard?.isSeedShard
                ? 'seed'
                : activeShard?.isDemoShard
                ? 'demo'
                : activeShard?.isSandboxShard
                ? 'sandbox'
                : ''
            }`}
          >
            {activeShard?.isSeedShard
              ? 'Seed'
              : activeShard?.isDemoShard
              ? 'Demo'
              : activeShard?.isSandboxShard
              ? 'Sandbox'
              : ''}
          </div>
        ) : null}
      </div>
    </HeaderWrapper>
  );
};
