import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { AppDispatch } from 'app/store';
import { useInterval } from 'hooks';
import { refreshSession, getSession } from 'utilities';

dayjs.extend(utc);

export const SessionTimer = (): null => {
  const session = useSelector(getSession);
  const { accessTokenExpires } = session;

  const [timeRemaining, setTimeRemaining] = useState(dayjs.utc(accessTokenExpires).diff(dayjs.utc(), 'second'));
  const [isRunning, setIsRunning] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setTimeRemaining(dayjs.utc(accessTokenExpires).diff(dayjs.utc(), 'second'));
  }, [accessTokenExpires]);

  useInterval(
    () => {
      setTimeRemaining(dayjs.utc(accessTokenExpires).diff(dayjs.utc(), 'second'));
    },
    isRunning ? 500 : undefined
  );

  useEffect(() => {
    if (timeRemaining < 30) {
      setIsRunning(false);
      dispatch(refreshSession());
    } else {
      setIsRunning(true);
    }
  }, [dispatch, timeRemaining]);

  return null;
};
