import { Api } from 'api';

export class ContactService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/shards/contact-info-nav';
    this.api = api;
  }

  public fetch() {
    return this.api.request<any>({
      method: 'GET',
      uri: this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': this.api.shardKey
      }
    });
  }
}
