import { withFormik } from 'formik';

import { IEmailTemplates, PageProps } from 'types';
import { getErrorMessage } from 'utilities';

import { EmailTemplatesForm } from '..';

interface IEmailTemplatesPage {
  onPost?: (args: any) => Promise<void>;
  isSending: boolean;
}

export const EmailTemplatesFormWrapper = withFormik<
  IEmailTemplates & PageProps<IEmailTemplates> & IEmailTemplatesPage,
  any
>({
  // Transform outer props into form values
  mapPropsToValues: ({ templates }): Pick<IEmailTemplates, 'templates'> => {
    return {
      templates
    };
  },
  handleSubmit: async (props, formik) => {
    const { name, endpoint, values, ...rest } = props;
    const {
      resetForm,
      props: { onSubmit, onSuccessToast, onFailureToast }
    } = formik;

    if (onSubmit) {
      try {
        await onSubmit(rest);
        onSuccessToast && onSuccessToast();
        resetForm({ values: rest });
      } catch (error) {
        const err = error as any;
        const msg = getErrorMessage(error) || err.message;
        onFailureToast && onFailureToast(msg);
      }
    }
  }
})(EmailTemplatesForm);
