import { Route } from 'types';

import { routes as configurationRoutes, baseRoute as configurationRoute } from './configurationRoutes';
import { routes as contentRoutes, baseRoute as contentRoute } from './contentRoutes';
import { routes as entitiesRoutes, baseRoute as entitiesRoute } from './entitiesRoutes';
import { routes as residentialSignUpRoutes, baseRoute as residentialSignUpRoute } from './residentialRoutes';

export const PATH = {
  ACCOUNT_SETTINGS: '/account-settings',
  ACTIVATE_ACCOUNT: '/activate',
  AUTO_ALLOCATIONS: '/auto-allocations',
  CONTACT_INFORMATION: '/contact-information',
  CONFIGURATION: configurationRoute,
  CONTENT: contentRoute,
  EMAIL_TEMPLATES: '/email-templates',
  INITIAL_DASHBOARD: '/initial-dashboard',
  ENTITIES: entitiesRoute,
  FORGOT_PASSWORD: '/forgot-password',
  KEYS: '/keys',
  LOGIN: '/login',
  NAVIGATION: '/navigation',
  RESET_PASSWORD: '/reset-password',
  RESIDENTIAL_SIGN_UP: residentialSignUpRoute,
  ROOT: '/',
  SETTINGS: '/settings',
  SHARDS: '/shards',
  THEME: '/theme',
  USERS: '/users',
};

export interface Routes {
  configuration: Route[];
  content: Route[];
  entities: Route[];
  residentialSignup: Route[];
}

export const routes: Routes = {
  configuration: configurationRoutes,
  content: contentRoutes,
  entities: entitiesRoutes,
  residentialSignup: residentialSignUpRoutes,
};
