import { IAssociatedUtility, IAssociatedUtilityFormValues } from 'types';
import { Api } from 'api';

export interface IAssociatedUtilitiesJSON {
  associatedUtilities: IAssociatedUtility[];
}

export class AssociatedUtilityService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/associated-utility';
    this.api = api;
  }

  public fetchAll() {
    return this.api.get<IAssociatedUtilitiesJSON>(this.baseUrl);
  }

  public update(associatedUtility: IAssociatedUtility) {
    return this.api.put<IAssociatedUtility>(this.baseUrl, associatedUtility);
  }

  public create(associatedUtility: IAssociatedUtilityFormValues) {
    return this.api.post<IAssociatedUtility>(this.baseUrl, associatedUtility);
  }

  public delete(id: string) {
    return this.api.delete(`${this.baseUrl}/${id}`);
  }
}
