import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Col,
  Input,
  Badge,
  CardFooter
} from 'reactstrap';
import { sentenceCase } from 'change-case';
import { FormikProps } from 'formik';

import { SaveButton } from 'components';
import { IEmailTemplates } from 'types';

enum NotificationType {
  NewUserInvite = 0,
  AlreadyHasAccount = 1,
  AutoPaymentFailed = 2,
  ForgottenPassword = 3,
  InviteProspect = 4,
  InvoiceGenerated = 5,
  PaymentReceipt = 6,
  ResidentialContractSigned = 7,
  InviteExistingCustomer = 8,
  InviteExistingPortfolio = 9,
  AutoPaymentReminder = 10,
  StatementGenerated = 11
}

interface ITemplate {
  name: string;
  type: NotificationType;
}

const templates: ITemplate[] = [
  { name: 'invite', type: NotificationType.NewUserInvite },
  { name: 'inviteProspect', type: NotificationType.InviteProspect },
  { name: 'alreadyHasAccount', type: NotificationType.AlreadyHasAccount },
  { name: 'autoPaymentFailed', type: NotificationType.AutoPaymentFailed },
  { name: 'forgotPassword', type: NotificationType.ForgottenPassword },
  { name: 'invoiceGenerated', type: NotificationType.InvoiceGenerated },
  { name: 'paymentReceipt', type: NotificationType.PaymentReceipt },
  { name: 'residentialContractSigned', type: NotificationType.ResidentialContractSigned },
  { name: 'autoPaymentReminder', type: NotificationType.AutoPaymentReminder },
  { name: 'statementGenerated', type: NotificationType.StatementGenerated }
];

interface IEmailTemplatesPage {
  onPost?: (args: any) => Promise<void>;
  isSending: boolean;
}

export const EmailTemplatesForm = (props: IEmailTemplates & FormikProps<IEmailTemplates> & IEmailTemplatesPage) => {
  const { dirty, isSending, handleSubmit, isSubmitting, setFieldValue, values, onPost } = props;

  const [testEmail, setTestEmail] = useState('');

  return (
    <Form>
      {templates.map(({ name, type }) => {
        const formValue = (values as any).templates[name];
        const { subject, body, tokens } = formValue;

        return (
          <Card className="mb-3" key={type}>
            <CardHeader tag="h5">{sentenceCase(name)}</CardHeader>

            <CardBody>
              <FormGroup row>
                <Label xs={12} for="status" className="font-weight-bold">
                  Subject
                </Label>

                <Col xs={12}>
                  <Input
                    disabled={isSubmitting}
                    onChange={e => setFieldValue(`templates.${name}.subject`, e.currentTarget.value)}
                    type="text"
                    value={subject}
                  />
                </Col>

                <Label xs={12} for="status" className="font-weight-bold">
                  Body
                </Label>

                <Col xs={12}>
                  <textarea
                    className="form-control text-monospace"
                    disabled={isSubmitting}
                    rows={10}
                    name={name}
                    onChange={e => setFieldValue(`templates.${name}.body`, e.currentTarget.value)}
                    value={body}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col xs={12}>
                  {tokens && (
                    <>
                      <h6>Tokens</h6>

                      {tokens.map((t: string) => (
                        <Badge key={t} color="info" className="p-2 mr-2 mb-2 text-monospace">
                          <h6 className="mb-0">{t}</h6>
                        </Badge>
                      ))}
                    </>
                  )}
                </Col>
              </FormGroup>
            </CardBody>

            <CardFooter>
              <div className="input-group">
                <Input
                  disabled={isSubmitting}
                  placeholder="Enter an email address to send a test"
                  onKeyDown={function(e) {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onPost &&
                        onPost({
                          sendTo: testEmail,
                          type
                        });
                    }
                  }}
                  type="text"
                  value={testEmail}
                  onChange={e => setTestEmail(e.currentTarget.value)}
                />

                <div className="input-group-append">
                  <Button
                    color="primary"
                    disabled={dirty || testEmail === '' || isSending}
                    onClick={() => {
                      onPost &&
                        onPost({
                          sendTo: testEmail,
                          type
                        });
                    }}
                  >
                    {isSending ? 'Sending ...' : 'Send Test'}
                  </Button>
                </div>
              </div>

              {dirty && <Alert color="warning">Save your changes first to send a test</Alert>}
            </CardFooter>
          </Card>
        );
      })}

      <SaveButton
        disabled={isSubmitting}
        onSubmit={() => handleSubmit()}
        text={isSubmitting ? 'Saving...' : 'Save'}
      />
    </Form>
  );
};
