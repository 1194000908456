import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'app/store';
import { getSession, clearSession, refreshSession } from 'utilities';

dayjs.extend(utc);

export const Session = (props: any): JSX.Element | null => {
  const { children } = props;

  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const session = useSelector(getSession);

  useEffect(() => {
    const { refreshTokenExpires } = session;

    if (refreshTokenExpires) {
      const timeRemaining = dayjs.utc(refreshTokenExpires).diff(dayjs.utc(), 'minute');

      if (timeRemaining <= 0) {
        dispatch(clearSession());
        setIsReady(true);
        return;
      } else {
        dispatch(refreshSession()).then(() => setIsReady(true));
        return;
      }
    }

    setIsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isReady) {
    return <>{children}</>;
  }

  return null;
};
