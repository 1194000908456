import { AppThunk } from 'app/store';
import { IAssociatedUtility, IAssociatedUtilityFormValues } from 'types';

export function updateAssociatedUtility(associatedUtility: IAssociatedUtility): AppThunk<Promise<void>> {
  return async function updatedAssociatedUtilityThunk(_dispatch, _getState, api) {
    try {
      await api.associatedUtility.update(associatedUtility);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function createAssociatedUtility(
  associatedUtility: IAssociatedUtilityFormValues
): AppThunk<Promise<IAssociatedUtility>> {
  return async function createAssociatedUtilityThunk(_dispatch, _getState, api) {
    try {
      const response = await api.associatedUtility.create(associatedUtility);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function deleteAssociatedUtility(id: string): AppThunk<Promise<void>> {
  return async function deleteAssociatedUtilityThunk(_dispatch, _getState, api) {
    try {
      await api.associatedUtility.delete(id);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
