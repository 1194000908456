import React from 'react';
import { Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { AppDispatch } from 'app/store';
import { Button } from 'components';
import { useForm } from 'hooks';
import { resetPassword } from 'utilities';

type ResetPasswordFormValues = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

type ResetPasswordFormProps = {
  buttonText: string;
  email: string | undefined;
  token: string;
};

export const ResetPasswordForm = (props: ResetPasswordFormProps): JSX.Element => {
  const { buttonText, email, token } = props;

  const dispatch = useDispatch<AppDispatch>();

  function onSubmit(): any {
    return dispatch(resetPassword(values.email, values.password, token));
  }

  function handleSuccess() {
    dispatch(push('/login', { message: 'New password set successfully.' }));
  }

  function handleFailure() {
    setValues({
      ...values,
      password: '',
      passwordConfirmation: ''
    });
  }

  const initialValues: ResetPasswordFormValues = {
    email: email || '',
    password: '',
    passwordConfirmation: ''
  };

  const form = useForm<ResetPasswordFormValues>(initialValues, onSubmit, handleSuccess, handleFailure);
  const { error, isSubmitting, values, handleInputChange, handleSubmit, setValues } = form;

  if (!token) {
    return <Redirect to="/" />;
  }

  return (
    <Form>
      {Boolean(error) && (
        <Alert className="text-center" color="danger">
          There was an error resetting your password. Please try again.
        </Alert>
      )}

      {!Boolean(email) && (
        <FormGroup>
          <Label for="email" className="font-weight-bold">
            Email Address
          </Label>

          <Input
            autoComplete="username"
            autoFocus={!Boolean(email)}
            disabled={isSubmitting}
            id="email"
            name="email"
            onChange={handleInputChange}
            tabIndex={1}
            type="email"
            value={values.email}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="password" className="d-flex font-weight-bold">
          Password
        </Label>

        <Input
          autoComplete="new-password"
          autoFocus={Boolean(email)}
          disabled={isSubmitting}
          id="password"
          name="password"
          onChange={handleInputChange}
          tabIndex={2}
          type="password"
          value={values.password}
        />
      </FormGroup>

      <FormGroup>
        <Label for="passwordConfirmation" className="d-flex font-weight-bold">
          Confirm Password
        </Label>

        <Input
          autoComplete="new-password"
          disabled={isSubmitting}
          id="passwordConfirmation"
          name="passwordConfirmation"
          onChange={handleInputChange}
          tabIndex={2}
          type="password"
          value={values.passwordConfirmation}
        />
      </FormGroup>

      <Button
        block
        loading={isSubmitting}
        color="primary"
        className="font-weight-bold"
        type="submit"
        onClick={handleSubmit}
        tabIndex={3}
      >
        {buttonText}
      </Button>
    </Form>
  );
};
