import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppDispatch } from 'app/store';
import { ShardList } from 'components';
import { getShards, fetchShards, getActiveShardKey } from 'utilities';

export const SelectShard = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const shards = useSelector(getShards);
  const activeShardKey = useSelector(getActiveShardKey);

  useEffect(() => {
    dispatch(fetchShards());
  }, [dispatch]);

  if (activeShardKey) {
    return <Redirect to="/" />;
  }

  return <ShardList shards={shards} forLogin={true} />;
};
