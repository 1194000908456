import { ElementType } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { CONFIGURATION_ENDPOINTS, CONTENT_ENDPOINTS, IMAGE_PURPOSE } from 'api';

enum NetPaymentTerms {
  'Net 10' = 0,
  'Net 15' = 1,
  'Net 30' = 2,
  'Net 45' = 3,
  'Net 7' = 5,
  'Net 20' = 6,
}

enum ShardStatus {
  Active = 0,
  Pending = 1,
  Inactive = 2,
}

/* ----------------------------- */

export interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export type ContentEndpointInterfaces = {
  [CONTENT_ENDPOINTS.CONTENT]: IContent;
  [CONTENT_ENDPOINTS.PRIVACY_POLICY]: IContent;
  [CONTENT_ENDPOINTS.TERMS_OF_USE]: IContent;
  [CONTENT_ENDPOINTS.INITIAL_DASHBOARD]: IInitialDashboard;
};

export interface IAddUserValues {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IAssociatedUtility {
  canDelete?: boolean;
  id: string;
  isNew?: boolean;
  name: string;
  utilityInfoApiId: null | string;
  utilityInfoApiProvider: null | string | UtilityInfoApiProvider;
}

export interface IAutoAllocation {
  decomposer: {
    defaultChunkSize: number;
    minimumChunkPercent: number;
  };
  preferenceWeights: {
    projects: {
      adjacencyCount: number;
      annualUsage: number;
      billCreditDelta: number;
      creditRate: number;
    };
    premises: {
      adjacencyCount: number;
      projectYield: number;
    };
  };
}

export interface IAssociatedUtilityFormValues {
  name: string;
  utilityInfoApiProvider: UtilityInfoApiProvider | string;
  utilityInfoApiId: string;
}

export type IConfigContent = {
  [CONFIGURATION_ENDPOINTS.SECRETS]: ISecrets;
  [CONFIGURATION_ENDPOINTS.CONTACT]: IContactInfo;
  [CONFIGURATION_ENDPOINTS.SETTINGS]: ISettings;
  [CONFIGURATION_ENDPOINTS.SANDBOX_SETTINGS]: ISandboxSettings;
  [CONFIGURATION_ENDPOINTS.AUTO_ALLOCATION]: IAutoAllocation;
  [CONFIGURATION_ENDPOINTS.EMAIL_TEMPLATES]: IEmailTemplates;
  [CONFIGURATION_ENDPOINTS.THEME]: ITheme;
  [CONFIGURATION_ENDPOINTS.POST_IMAGE]: ITheme;
  [CONFIGURATION_ENDPOINTS.INVOICE_INVOICE_PREVIEW]: IInvoicePreview;
};

export interface IContactInfo {
  contactInfo: {
    company: {
      name: string;
      legalName: string;
      address: string;
      address2: string;
      city: string;
      state: string;
      zipCode: string;
      phoneNumber: string;
    };
    remittance: {
      name: string;
      address: string;
      address2: string;
      city: string;
      state: string;
      zipCode: string;
      phoneNumber: string;
      email: string;
    };
    email: {
      contact: string;
      autoMailer: string;
    };
  };
  navigation: {
    customerSidebar: INavigationItem[];
    residentialNoProjectsInAreaUrl: string;
  };
}

export interface IContent {
  content: string;
  tokens: string[];
}

export interface IInitialDashboard {
  aboutUs: boolean;
  aboutUsMetrics: {
    megaWattsManaged: number;
    numberOfProjects: number;
    numberOfSubscribers: number;
  };
  commonQuestions: {
    order: number;
    question: string;
  }[];
}

export interface IEmailTemplates {
  autoMailerFromAddress: string;
  contactEmailAddress: string;
  templates: {
    invite: {
      subject: string;
      body: string;
      tokens: string[];
    };
    inviteProspect: {
      subject: string;
      body: string;
      tokens: string[];
    };
    alreadyHasAccount: {
      subject: string;
      body: string;
      tokens: string[];
    };
    autoPaymentFailed: {
      subject: string;
      body: string;
      tokens: string[];
    };
    forgotPassword: {
      subject: string;
      body: string;
      tokens: string[];
    };
    invoiceGenerated: {
      subject: string;
      body: string;
      tokens: string[];
    };
    paymentReceipt: {
      subject: string;
      body: string;
      tokens: string[];
    };
    residentialContractSigned: {
      subject: string;
      body: string;
      tokens: string[];
    };
  };
}

export interface IFaqItem {
  content: string;
  title: string;
}

export interface IFaqs {
  items: IFaqItem[];
}

export interface IInvoicePreview {
  ticket: string;
}

export type ImageResponse = {
  data: {
    url: string;
  };
};

export interface INavigationItem {
  title: string;
  icon: string;
  url: string;
}

export interface IPageBlock {
  body: string | undefined;
  title: string | undefined;
}

export interface IResidentialContent {
  addUtility: IPageBlock;
  confirmUtility: IPageBlock;
  connectUtility: IPageBlock;
  creditCheck: IPageBlock;
  homepage: IResidentialHomepage;
  inquiry: IPageBlock;
  inquiryFailure: IPageBlock;
  inquirySuccess: IPageBlock;
  plans: IPageBlock;
  setPassword: IPageBlock;
  shouldShowNotYetCompleteMessage: boolean;
  verifyAccount: IPageBlock;
  review: IPageBlock;
  finalize: IPageBlock;
  finalizeUCB: IPageBlock;
}

export interface IResidentialHomepage {
  blocks: IPageBlock[];
  form: IPageBlock;
  lead: string | undefined;
  media: string | undefined;
  mediaPosition: number | undefined;
  subtitle: string | undefined;
  title: string | undefined;
}

export interface IResidentialSignUpContent {
  content: IResidentialContent;
  faqs: IFaqs;
}

export interface ISecrets {
  analytics: {
    globalKey: string;
    shardKey: string;
  };
  gtm: {
    key: string;
  };
  plaid: {
    clientId: string;
    secret: string;
    environment: string;
  };
  stripe: {
    publishableKey: string;
    secretKey: string;
    webhook: string;
    webhookUrl: string;
  };
  utilityApi: {
    webhook: string;
    webhookUrl: string;
  };
  monitoring: {
    key: string;
  };
  clarity: {
    key: string;
  };
}

export interface ISettings {
  status: ShardStatus;
  features: ISettingsFeatures;
  passwordRequirements: ISettingsPasswordRequirements;
  cache: {
    defaultShardCacheTimeInMinutes: number; // 0 to 3600
  };
  payments: ISettingsPayments;
  creditCheck: ISettingsCreditCheck;
}

export interface ISandboxSettings {
  url: string;
  canResetDatabase: boolean;
}

export interface ISettingsCreditCheck {
  askForFullSsn: boolean;
  askForLast4: boolean;
  askForDob: boolean;
  transunion: {
    useProductionUrl: boolean;
    useProductionEnvironment: boolean;
    subscriberCode: string; // length 32
    password: string; // length 32
  };
}

export interface ISettingsFeatures {
  disableInvoiceSunCentralDisclaimer: boolean;
  enableStreamlinedSignUpFlows: boolean;
  enableWelcomeMessage: boolean;
  enablePaymentMessage: boolean;
  hidePoweredBySunCentral: boolean;
  invoicePremiseShareKilowattHourPrecision: number; // 0 to 3
  isResidentialDashboardEnabled: boolean;
  isResidentialRegistrationEnabled: boolean;
  isResidentialExpectedSavingsDisabled: boolean;
  remitToPortfolioOwnerIfUnique: boolean;
  residentialRegistrationSubscriptionTimeToLiveInHours: number; // 1 to 72
  enablePlaid: boolean;
  disableSunCentralWebhooks: boolean;
  consentDocumentTypes: string;
  hubspotChatUrl: string;
  residentialMaxAnnualUsage: number;
  enableSignUpFlowDocumentUpload: boolean;
}

export interface ISettingsPasswordRequirements {
  requiredLength: number; // 8 to 15
  requireDigit: boolean;
  requireLowercase: boolean;
  requireNonLetterOrDigit: boolean;
  requireUppercase: boolean;
}

export interface ISettingsPayments {
  defaultNetPaymentTerms: NetPaymentTerms;
  portfolioDaysToMature: number; // 0 to 90
  paymentMethods: PaymentMethods[];
  optIntoAutoPaymentByDefault: boolean;
  daysInAdvanceToRemindAutoPayment: number;
  statementDescriptor: string;
}

export interface ITheme {
  theme: {
    mainLogoUrl: string;
    backgroundImageUrl: string;
    faviconLogoUrl: string;
    primaryColor: string;
    lightPrimaryColor: string;
    secondaryColor: string;
  };
  invoices: {
    invoiceLogoUrl: string;
    invoiceHeaderBackgroundColor: string;
    invoiceHeaderFontColor: string;
  };
  residential: {
    residentialBackgroundImageUrl: string;
    residentialPartnerLogoUrl: string;
    residentialColor1: string;
    residentialColor2: string;
    residentialColor3: string;
    residentialColor4: string;
  };
}

export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export type PageKey =
  | 'addUtility'
  | 'confirmUtility'
  | 'connectUtility'
  | 'creditCheck'
  | 'inquiry'
  | 'inquiryFailure'
  | 'inquirySuccess'
  | 'plans'
  | 'review'
  | 'finalize'
  | 'finalizeUCB'
  | 'setPassword'
  | 'verifyAccount';

export interface PageProps<V> {
  name: Route['name'];
  endpoint: CONTENT_ENDPOINTS | CONFIGURATION_ENDPOINTS;
  onSubmit?: (args: V) => Promise<void>;
  onPost?: (args: V) => Promise<void>;
  onSuccessToast?: (msg?: string, autoDismiss?: boolean) => void;
  onFailureToast?: (msg?: string, autoDismiss?: boolean) => void;
  onPostFile?: (args: FormData, imagePurpose: IMAGE_PURPOSE) => Promise<ImageResponse>;
  values: V;
  tokens?: string[];
}

export enum PaymentMethods {
  CreditCard = 0,
  Ach = 1,
}

export interface ProfileFormValues {
  firstName: string;
  lastName: string;
}

export type Route = {
  component: ElementType;
  exact?: boolean;
  key: string;
  name: string;
  path: string;
  routes?: Route[];
  icon?: IconDefinition;
  hide?: boolean;
};

export type RouterProps = {
  baseUrl: string;
  routes: Route[];
  baseUrlPath?: string;
};

export enum UtilityInfoApiProvider {
  UtilityAPI,
}

/* -------------------------- */
