import { useToasts } from 'react-toast-notifications';

import { api } from 'app/store';
import { ChangePasswordFormValues } from 'types';

export function useChangePassword() {
  const { addToast } = useToasts();

  function onSubmit(values: ChangePasswordFormValues) {
    return api.account
      .changePassword(values)
      .then(
        () => addToast('Password saved successfully.', { appearance: 'success' }),
        () => addToast('There was an error changing your password. Please try again.', { appearance: 'error' })
      );
  }

  return {
    onSubmit
  };
}