import { Api } from 'api';

export enum ShardStatus {
  Active,
  Pending,
  Inactive,
}

export interface IHost {
  id: string;
  uiUrl: string;
  uiHostname: string;
  apiHostname: string;
  shardProfileId: string;
  key: string;
}

export interface IShard {
  id: string;
  name: string;
  logoUrl: string;
  status: ShardStatus;
  hosts: IHost[];
  key: string;
  canResetDatabase: boolean;
  defaultShardHostId: string;
  hostname: string;
  isDemoShard: boolean;
  isSandboxShard: boolean;
  isSeedShard: boolean;
  sandboxKey: string;
  sandboxOfShardProfileId: string;
  dataResetTracking?: string;
}

export interface IShardLastReset {
  id: string;
  dataResetTracking: string;
  canResetDatabase: boolean;
  key?: string;
}

export class ShardService {
  private api: Api;
  private baseUrl: string;

  constructor(api: Api) {
    this.baseUrl = '/shards';
    this.api = api;
  }

  public list() {
    return this.api.request<IShard[]>({
      method: 'GET',
      uri: this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
      },
    });
  }

  public shardResetStatus(shardKey: string) {
    return this.api.request<IShardLastReset>({
      method: 'GET',
      uri: `${this.baseUrl}/reset-shard`,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': shardKey,
      },
    });
  }

  public shardReset(shardKey: string) {
    return this.api.request({
      method: 'DELETE',
      uri: `${this.baseUrl}/reset-shard`,
      headers: {
        Authorization: `Bearer ${this.api.session.accessToken}`,
        'X-Shard-Key': shardKey,
      },
    });
  }
}
