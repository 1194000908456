import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { api } from 'app/store';
import { ProfileFormValues } from 'types';
import { getUserId } from 'utilities';

export function useUpdateAccount() {
  const userId = useSelector(getUserId) || '';
  const { addToast } = useToasts();

  function onSubmit(values: ProfileFormValues) {
    return api.account
      .updateAccount(userId, values)
      .then(
        () => addToast('Profile saved successfully.', { appearance: 'success' }),
        () => addToast('There was an error saving the content. Please try again.', { appearance: 'error' })
      );
  }

  return {
    onSubmit
  };
}

