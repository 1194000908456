import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { RouterProps as SubRouterProps } from 'types';

export const SubRouter = (props: SubRouterProps): JSX.Element => {
  const { baseUrl, routes, baseUrlPath } = props;

  return (
    <>
      {routes.map(route => {
        const { component: Component, exact, key, name, path, routes } = route;

        return (
          <Route
            exact={exact}
            key={key}
            path={`${baseUrl}${path}`}
            render={props => (
              <Component baseUrl={props.match.path} pageKey={key as string} name={name} routes={routes} />
            )}
          />
        );
      })}

      <Route exact path={`${baseUrl}`} render={props => <Redirect to={`${props.match.path}${baseUrlPath}`} />} />
    </>
  );
};
